import React from 'react'

export default function Ateliers () {
    return (
        <main>
            Les ateliers sont ouverts à tous, vous trouverez ci-dessous les thèmes abordés.<br/>
            Ils sont organisés au cabinet, vous pourrez retrouver l'actualité sur <a href="https://www.facebook.com/Laurence-Bodennec-accompagnement-et-hypnose-111551404985801/">Ma page Facebook</a> ou sur <a href="https://www.instagram.com/laurencebodennechypnose">Mon compte Instagram</a>.<br/>
            Ils peuvent également se tenir en entreprises, associations,  écoles, bibliothèques sur demande. 
            D'autres possibilités de thèmes selon vos besoins.
            
            <h2>Améliorer son sommeil grâce à l'hypnose</h2>
            <h2>Gestion du stress et apport de l'hypnose</h2>
            <h2>Gagner en confiance en soi avec l'hypnose</h2>
            <h2>Gagner en estime de soi avec l'hypnose</h2>
            <h2>Mieux comprendre, mieux gérer ses émotions avec l'hypnose</h2>
            <h2>Gagner en affirmation de soi</h2>
            <h2>Mots et merveilles : </h2>
            Ateliers autour du livre, du conte, ou tout autre atelier avec les mots et ressources de chacun (Voyage autour des sens, autohypnose, relaxation.)...
            <video width="100%" controls>
                <source src="https://hypnoz-storage.s3.eu-west-3.amazonaws.com/motsEtMerveilles.mp4" type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
        </main>
    )
}