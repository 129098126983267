import React from 'react'
import lolo from "../../images/lolo5.jpg"
const roundImage = {
  height:200,
  borderRadius: "50%"
}

const divStyle = {
  display: 'flex', 
  alignItems:'center',
  justifyContent: 'center'
}
export default function Praticienne () {
  return (
      <main>
        <div width="100%" style={divStyle}>
        <img src={lolo} alt="Laurence Bodennec" style={roundImage}/>
        </div>
        <div width="100%" style={divStyle}>
        <h2>Laurence Bodennec</h2>
        </div>
        <p>
        Riche d’une expérience de 16 ans en santé mentale, j’ai développé des aptitudes
relationnelles, humaines et d’écoute.<br/>
Cette expérience me permet de vous proposer un accompagnement centré sur votre mieux-être.<br/>
J'ai validé ces acquis par l'obtention du <b>D</b>iplôme <b>U</b>niversitaire "Hypnose médicale et clinique" à l'Université de Bretagne Occidentale (Brest) en 2021.<br/><br/>
Les séances peuvent être de l’ordre du soutien, d’un appui, ou centrées sur un objectif précis
avec l’utilisation de l’outil hypnose.<br/>
Accompagner, ce n’est pas être devant, derrière ou faire à la place. C’est être à côté. A coté
de vos craintes, de vos peurs, de vos besoins, de vos envies. Je vais donc à votre rythme.
Chaque être est unique et à des besoins différents. Je m’accorde à être à l’écoute pour vous
aider au mieux à avancer, cheminer. Tel un guide, un accompagnant, un aidant chacun avec
ses propres mots et perceptions.<br/><br/>
Au plaisir de vous recevoir.<br/><br/>
        </p>
        <center>
        <div style={{ backgroundColor:"#FFFFFF60", padding:5, borderRadius:10}}>
          
          <p style={{ ontStyle:"italic", fontSize:20, fontWeigth:"bold"}}>
          <b>&laquo;&nbsp;</b>Changer n'est pas devenir quelqu'un d'autre, c'est devenir qui on est et l'accepter (J.&nbsp;Salomé)<b>&nbsp;&raquo;</b>
        </p>
       
        </div>
        </center>
      </main>
  )
}