import React from 'react'


export default function Prestations () {
  return (
      <main>

 <h2>Individuel</h2>
 <ul>
 <li><b>65€</b> la séance </li>
 <li><b>50€</b> pour les enfants, les adolescents (-16 ans)</li>
 <li><b>50€</b> tarif social (étudiants, chômeurs ...)</li>
 </ul>
 <h2>En groupe</h2>
 <ul>
 <li><b>2O€</b>&nbsp;la séance en groupe par personne</li>
 </ul>
 <h3>En entreprise : me contacter</h3>
 <span>Paiement en espèces ou par chèque uniquement</span>
        
        </main>
    )
}