import * as React from "react"
import Sidebar from "../components/sidebar"
import Content from "../components/content"
import ImgLogoVertical from "../components/imgLogoVertical"
import "../assets/css/styles.css"
import Copyright from '../components/copyright'
import SEO from "../components/seo"


export default function Index () {
  return (
      <main>
        <SEO />
        <div  className="layoutSidebar">
          <Sidebar />
        </div>
        <ImgLogoVertical />
        <div  className="layoutMain">
          <Content title="Votre praticienne" id="praticienne"/>
          <Content title="L'hypnose" id="hypnose"/>
          <Content title="Indications"  id="indications"/>
          <Content title="Témoignages"  id="temoignages"/>
          <Content title="Ateliers"  id="ateliers"/>
          <Content title="Tarifs"  id="prestations"/>
          <Content title="Pratique"  id="contact"/>
          <Copyright />
        </div>
      </main>
  )
}
